import { useState } from 'react';

export const useLeftExpandCollapsePanel = () => {
	const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState<boolean>(false);

	const handleChangeExpandLeftPanel = (newValue: boolean) => {
		setIsLeftPanelExpanded(newValue);
	};

	return {
		isLeftPanelExpanded,
		handleChangeExpandLeftPanel,
	};
};
