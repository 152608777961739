import { Center, Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import { useModal } from 'src/common/hooks/ui/useModal';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { AddSmall16, OkBig, Plus16 } from '../Icons';
import { TestIDs } from 'src/common/types/test-ids';
import { useDashboardPageSearchParams } from 'src/pages/DashboardPage/hooks/useDashboardPageSearchParams';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';

export function CollectionEmptyPage({ collectionType }: { collectionType: string }) {
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	const { folderId } = useDashboardPageSearchParams();
	const { isOpen, onOpen, onClose } = useModal();

	const foldersEnabledEmptyState = (
		<Flex
			paddingBottom={'110px'}
			w={'100%'}
			height={'100%'}
			justifyContent={'center'}
			alignItems="center"
			direction="column"
		>
			<Flex gap={'20px'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
				<OkBig />
				<Flex flexDirection={'column'} gap={'4px'}>
					<Typography color={'gray.1000'} variant={'DesktopH7Medium'} textAlign="center">
						Create a dashboard
					</Typography>
					<Typography color={'gray.1000'} variant={'DesktopH7Regular'} textAlign="center">
						Start by creating one to organize your reports.
					</Typography>
				</Flex>
				<Button
					borderRadius={'8px'}
					leftIcon={<Plus16 />}
					variant="solid"
					onClick={onOpen}
					size="small"
					colorScheme="blue"
				>
					Create
				</Button>
			</Flex>
		</Flex>
	);

	const collectionEmptyState = (
		<Flex direction="column" alignItems={'center'}>
			<Box paddingBottom={'20px'} data-testid={TestIDs.EMPTY_COLLECTION_PAGE_TITLE(collectionType)}>
				<Typography color={'gray.1000'} variant={'DesktopH5S'}>
					Create your first {collectionType}.
				</Typography>
			</Box>
			<Button leftIcon={<AddSmall16 />} variant="solid" colorScheme="blue" size="small" onClick={onOpen}>
				Add new {collectionType}
			</Button>
		</Flex>
	);

	return (
		<Center height={'100%'}>
			{isDashboardFoldersEnable ? foldersEnabledEmptyState : collectionEmptyState}
			<CollectionUpsertModal folderId={folderId} isOpen={isOpen} onClose={onClose} collectionType={collectionType} />
		</Center>
	);
}
