import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonFolder.module.scss';

const REPEAT_FOLDERS_NUMBER = 17;

export function SkeletonFolder() {
	const { skeletonWrapper, folderSkeleton } = classes;

	return (
		<>
			<Flex className={skeletonWrapper}>
				{[...Array(REPEAT_FOLDERS_NUMBER)].map((_, index) => (
					<Skeleton key={index} className={folderSkeleton} />
				))}
			</Flex>
		</>
	);
}
