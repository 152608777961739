import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import Typography from '@components/Typography';
import { ChevronDown16, ChevronUp16, DashboardSpace16, WorkspaceIcon } from '@icons/index';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import classNames from 'classnames';
import { useRef } from 'react';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';

const ICON_COLOR = colors.gray['1000'];

export const SpaceSelector = ({
	isDashboardsPage,
	setIsPanelActingLikeOnHover,
}: {
	isDashboardsPage: boolean;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const refSpaceSelector = useRef<HTMLDivElement>(null);
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { navigate } = useNavigation();

	const onSelectorOpen = () => {
		onOpen();
		setIsPanelActingLikeOnHover(true);
	};

	const onSelectorClose = () => {
		onClose();
		setIsPanelActingLikeOnHover(false);
	};

	return (
		<Popover
			triggerElement={
				<Box ref={refSpaceSelector}>
					<Flex
						className={classNames(classes.menuIndicator, classes.spaceSelector)}
						onClick={() => {
							if (isOpen) onSelectorClose();
							else onSelectorOpen();
							reportEvent({ event: 'space-selector-clicked' });
						}}
						alignItems={'center'}
						justifyContent={'space-between'}
						padding={'8px 6px 8px 16px'}
					>
						<Flex alignItems={'center'} gap={'12px'}>
							{isDashboardsPage ? <DashboardSpace16 color={ICON_COLOR} /> : <WorkspaceIcon color={ICON_COLOR} />}
							<Typography noOfLines={1} cursor={'pointer'} variant="DesktopH8Regular" color={ICON_COLOR}>
								{isDashboardsPage ? 'Dashboards' : 'Workspaces'}
							</Typography>
						</Flex>
						{isOpen ? <ChevronUp16 color={ICON_COLOR} /> : <ChevronDown16 color={ICON_COLOR} />}
					</Flex>
				</Box>
			}
			isOpen={isOpen}
			placement={'bottom-start'}
			onClose={onSelectorClose}
			popoverContentProps={{
				boxShadow: shadows['panelShadow'],
				borderRadius: '8px',
				border: `1px solid ${colors.gray[300]}`,
			}}
		>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="224px">
					<ListItem
						color="gray.1000"
						hoverColor={'blue.100'}
						label="Dashboards"
						size="sm"
						onClick={() => {
							reportEvent({ event: 'navigate-to-dashboard-clicked' });
							navigate({ path: 'dashboard' });
						}}
						state={isDashboardsPage ? 'selected' : 'enabled'}
						prefixIcon={<DashboardSpace16 />}
					/>
					<ListItem
						color="gray.1000"
						hoverColor={'blue.100'}
						label="Workspaces"
						size="sm"
						onClick={() => {
							reportEvent({ event: 'navigate-to-workspace-clicked' });
							navigate({ path: 'workspace' });
						}}
						state={!isDashboardsPage ? 'selected' : 'enabled'}
						prefixIcon={<WorkspaceIcon />}
					/>
				</Flex>
			</PopoverContent>
		</Popover>
	);
};
