import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import { AccessController } from '@components/AccessController';
import Box from '@components/Box';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import {
	ArrowUpRight,
	DeleteTrash16,
	//  DuplicateSolid16,
	EditFull16,
	Link16,
	More16,
} from '@icons/index';
import { Collection } from '@layout/Menu/NavigationDrawer/types';
import { useReportEvent } from '@services/analytics';
import useNavigation, { NavigationOptions } from '@services/useNavigation';
// import useMutation from 'src/common/hooks/fetching/useMutation';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
// import useToast from 'src/common/hooks/ui/useToast';
// import { InsertCollectionAndFeed } from 'src/queries/collections';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';

type PopoverMenuItemsProps = {
	onEdit: VoidFunction;
	onDelete: VoidFunction;
	onNavigate: VoidFunction;
	onCopyLink: VoidFunction;
	onMoveTo: VoidFunction;
	// onDuplicate: VoidFunction;
};

export const SpaceMoreButton = ({
	collection,
	setIsPanelActingLikeOnHover,
	onCreateEditModalOpen,
	onDeleteModalOpen,
	onUpsertFolderModalOpen,
	setActionItem,
	navigateToCollection,
}: {
	collection: Collection;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	onCreateEditModalOpen: VoidFunction;
	onDeleteModalOpen: VoidFunction;
	onUpsertFolderModalOpen: VoidFunction;
	setActionItem: (collection: Collection) => void;
	navigateToCollection: (
		collectionType: string,
		collectionId: string,
		collectionName: string,
		navigate: (navOptions: NavigationOptions) => void,
		isNewTab?: boolean
	) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { onCopyText } = useOnCopy();
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	// const toast = useToast();
	// const [collectionMutate, { loading: isDuplicateLoading }] = useMutation(InsertCollectionAndFeed);

	const onMenuOpen = () => {
		onOpen();
		setIsPanelActingLikeOnHover(true);
	};

	const onMenuClose = () => {
		onClose();
		setIsPanelActingLikeOnHover(false);
	};

	const handleDelete = () => {
		onCloseAndSelect();
		onDeleteModalOpen();
	};

	const handleEdit = () => {
		onCloseAndSelect();
		onCreateEditModalOpen();
	};

	const handleMoveTo = () => {
		onCloseAndSelect();
		onUpsertFolderModalOpen();
	};

	const onCloseAndSelect = () => {
		onMenuClose();
		setActionItem(collection);
	};

	const onCopy = () => {
		onMenuClose();
		const currentURL = `${window.location.origin}/dashboard/${collection.id}${window.location.search}`;
		void onCopyText({ contentToCopy: currentURL });
	};

	// TODO: uncomment all duplicate logic after back-end unique name changes
	// const handleDuplicate = async () => {
	// 	try {
	// 		await collectionMutate({
	// 			variables: {
	// 				name: collection.name,
	// 				description: collection.description,
	// 				collection_type: 'dashboard',
	// 				folder_id: collection.folder_id,
	// 			},
	// 		}).then(() => {
	// 			toast({
	// 				variant: 'ok',
	// 				message: 'Successfully duplicated',
	// 			});
	// 		});
	// 	} catch (error) {
	// 		if (error instanceof Error) toast({ variant: 'error', message: error.message });
	// 	}
	// };

	const isDashboardCollection = collection.collection_type === 'dashboard';

	const PopoverMenuItems = ({
		onNavigate,
		onEdit,
		onDelete,
		onCopyLink,
		onMoveTo,
	}: // onDuplicate,
	PopoverMenuItemsProps) => (
		<>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="175px" borderRadius="4px">
					{!isDashboardCollection && (
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							label="Open in new tab"
							size="sm"
							prefixIcon={<ArrowUpRight />}
							onClick={onNavigate}
						/>
					)}
					{isDashboardCollection && (
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							label="Copy link"
							size="sm"
							prefixIcon={<Link16 />}
							onClick={onCopyLink}
						/>
					)}
					<AccessController permission={Permissions.updateCollection}>
						{/* {isDashboardCollection && (
							<ListItem
								state={isDuplicateLoading ? 'disabled' : 'enabled'}
								color="gray.1000"
								hoverColor={'gray.200'}
								label="Duplicate"
								size="sm"
								prefixIcon={<DuplicateSolid16 />}
								onClick={onDuplicate}
							/>
						)} */}
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Edit"
							prefixIcon={<EditFull16 />}
							onClick={onEdit}
						/>
						{isDashboardCollection && isDashboardFoldersEnable && (
							<ListItem
								color="gray.1000"
								hoverColor={'gray.200'}
								label="Move to..."
								size="sm"
								prefixIcon={<ArrowUpRight />}
								onClick={onMoveTo}
							/>
						)}
					</AccessController>
					<Box px={'12px'}>
						<Divider direction="horizontal" my="8px" />
					</Box>
					<AccessController permission={Permissions.deleteCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'blue.100'}
							size="sm"
							label="Delete"
							prefixIcon={<DeleteTrash16 />}
							onClick={onDelete}
						/>
					</AccessController>
				</Flex>
			</PopoverContent>
		</>
	);

	return (
		<Box className={classes.moreButton}>
			<Popover
				placement="bottom-start"
				variant="solid"
				isOpen={isOpen}
				onClose={onMenuClose}
				triggerElement={
					<Flex position="relative">
						<Button
							borderRadius={'4px'}
							size="xxxs"
							variant="outline"
							colorScheme="gray"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								reportEvent({ event: 'collection-dots-menu-clicked', metaData: { name: collection.name } });
								if (isOpen) onMenuClose();
								else onMenuOpen();
							}}
							isActive={isOpen}
							isIconOnly={true}
							color={'gray.900'}
							_hover={{ bgColor: 'rgba(245, 245, 245, 0.50)' }}
							blendMode={'multiply'}
						>
							<More16 />
						</Button>
					</Flex>
				}
				popoverContentProps={{
					boxShadow: shadows['panelShadow'],
					borderRadius: '8px',
					border: `1px solid ${colors.gray[300]}`,
				}}
			>
				<PopoverMenuItems
					onNavigate={() => {
						reportEvent({ event: 'open-collection-in-new-tab', metaData: { name: collection.name } });
						onMenuClose();
						navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, true);
					}}
					onCopyLink={() => {
						reportEvent({ event: 'sidebar-copy-link-to-dashboard', metaData: { name: collection.name } });
						onCopy();
					}}
					onEdit={() => {
						reportEvent({
							event: 'sidebar-edit-item',
							metaData: { itemType: collection.collection_type, name: collection.name },
						});
						onMenuClose();
						handleEdit();
					}}
					// onDuplicate={async () => {
					// 	reportEvent({
					// 		event: 'sidebar-duplicate-dashboard',
					// 		metaData: { name: collection.name },
					// 	});
					// 	await handleDuplicate();
					// }}
					onMoveTo={() => {
						reportEvent({
							event: 'sidebar-move-dashboard-clicked',
							metaData: { name: collection.name },
						});
						onMenuClose();
						handleMoveTo();
					}}
					onDelete={() => {
						reportEvent({
							event: 'sidebar-delete-item',
							metaData: { itemType: collection.collection_type, name: collection.name },
						});
						onMenuClose();
						handleDelete();
					}}
				/>
			</Popover>
		</Box>
	);
};
