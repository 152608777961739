import {
	useDeleteFolderMutation,
	useEditFolderMutation,
	useInsertFolderMutation,
	useReorderDashboardMutation,
	useReorderFolderMutation,
} from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import useToast from './ui/useToast';
import { FolderData } from '../components/LeftSidePanelV2/DashboardFolder/UpsertFolderModal';

export const useDashboardFolders = () => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });
	const toast = useToast();
	const [insertFolder, { loading: isCreateFolderLoading }] = useInsertFolderMutation();
	const [deleteFolder, { loading: isDeleteFolderLoading }] = useDeleteFolderMutation();
	const [editFolder, { loading: isEditFodlerLoading }] = useEditFolderMutation();
	const [reorderFolder] = useReorderFolderMutation();
	const [reorderDashboard] = useReorderDashboardMutation();

	const onError = (err: unknown) => {
		if (err instanceof Error) toast({ variant: 'error', message: err.message });
	};

	const createDashboardFolder = async (name: string, emoji: string, isWithToast?: boolean) => {
		reportEvent({ event: 'sidebar-create-new-item-create', metaData: { itemType: 'folder' } });
		try {
			const insertFolderResult = await insertFolder({ variables: { name, emoji } });
			if (!insertFolderResult.data) return;
			if (isWithToast) toast({ variant: 'ok', message: 'Successfully created folder' });
			return insertFolderResult.data;
		} catch (error) {
			onError(error);
		}
	};

	const deleteDashboardFolder = async (id: string) => {
		reportEvent({ event: 'sidebar-delete-item', metaData: { itemType: 'folder' } });
		try {
			const insertFolderResult = await deleteFolder({ variables: { id } });
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully deleted folder' });
		} catch (error) {
			onError(error);
		}
	};

	const editDashboardFolder = async (folder: FolderData) => {
		const { id, folderName, emoji } = folder;
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		try {
			const insertFolderResult = await editFolder({ variables: { id, name: folderName, emoji } });
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully updated folder' });
		} catch (error) {
			onError(error);
		}
	};

	const reorderDashboardFolder = async (id: string, new_order: number) => {
		try {
			await reorderFolder({ variables: { id, new_order: Math.floor(new_order) } });
		} catch (error) {
			onError(error);
		}
	};

	const reorderFolderDashboard = async (dashboard_id: string, new_folder_id: string, new_order: number) => {
		try {
			await reorderDashboard({ variables: { dashboard_id, new_folder_id, new_order: Math.floor(new_order) } });
		} catch (error) {
			onError(error);
		}
	};

	return {
		createDashboardFolder,
		isCreateFolderLoading,
		deleteDashboardFolder,
		isDeleteFolderLoading,
		editDashboardFolder,
		isEditFodlerLoading,
		reorderDashboardFolder,
		reorderFolderDashboard,
	};
};
