import { Flex } from '@chakra-ui/react';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { FolderData } from './UpsertFolderModal';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { Fragment, useMemo } from 'react';
import Typography from '../../Typography';

export function DeleteFolderModal({
	isOpen,
	onClose,
	folder,
}: {
	isOpen: boolean;
	onClose: () => void;
	folder: FolderData;
}) {
	const { deleteDashboardFolder, isDeleteFolderLoading } = useDashboardFolders();

	const onDeleteFolder = async () => {
		if (!folder.id) return;
		await deleteDashboardFolder(folder.id);
		onClose();
	};

	const onCancelDelete = () => {
		onClose();
	};

	const dashboardsToDelete = useMemo(() => folder.dashboards?.map((el) => el.name) || [], [folder.dashboards]);

	return (
		<ConfirmationModal
			primaryButtonLabel={'Delete'}
			isOpen={isOpen}
			modalText={
				<Flex direction={'column'} gap="10px">
					{dashboardsToDelete.length > 0 ? (
						<>
							<Typography variant={'DesktopH8Regular'} textAlign="center">
								<Typography variant={'DesktopH8Bold'}>{folder.folderName}</Typography>
								&nbsp;and all its dashboards will be permanently deleted.
							</Typography>
						</>
					) : (
						<Typography variant={'DesktopH8Regular'} textAlign="center">
							<Typography variant={'DesktopH8Bold'}>{folder.folderName}</Typography>
							&nbsp; will be permanently deleted.
						</Typography>
					)}
				</Flex>
			}
			modalTitle={'Delete folder.'}
			onSubmit={onDeleteFolder}
			onClose={onCancelDelete}
			isLoading={isDeleteFolderLoading}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
		/>
	);
}
