import { ApolloError } from '@apollo/client';
import { DashboardGetSingleQuery, useGetDashboardsFromSingleFolderQuery } from 'src/generated/graphql';
import { DashboardGetSingle, DashboardGetSingleByOrder } from 'src/queries/dashboards';
import useUser from '../stores/useUser';
import { useHasuraSubscriptionWithCache } from './useSubscription';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useNavigation from 'src/services/useNavigation';
import { useEffect, useMemo } from 'react';
import _ from 'lodash';

export function useGetDashboardSubscription({
	id,
}: {
	id: string | undefined;
}): [DashboardGetSingleQuery | undefined, ApolloError | undefined, boolean] {
	const [{ id: my_id }] = useUser();
	const { navigate } = useNavigation();

	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');

	const document = id ? DashboardGetSingle : DashboardGetSingleByOrder;
	const variables = id ? { id, my_id } : { offset: 0, my_id };

	const isDashboardFromList = isDashboardFoldersEnable && !id;
	const isFolderPage = window.location.href.includes('dashboard/folder/');

	const { data, error, loading } = useHasuraSubscriptionWithCache(document, {
		variables,
	});

	const { data: foldersList } = useGetDashboardsFromSingleFolderQuery({
		variables: { offset: 0, my_id: my_id },
		fetchPolicy: 'no-cache',
	});

	const sortedFolders = useMemo(() => _.sortBy(foldersList?.folders, 'order'), [foldersList?.folders]);

	useEffect(() => {
		if (isDashboardFromList) {
			const fristFolder = sortedFolders[0];
			if (fristFolder && !fristFolder.dashboards.length) {
				navigate({ path: `dashboard/folder/${fristFolder.id}` });
			}
		}
	}, [sortedFolders, navigate, isDashboardFromList]);

	const response = isFolderPage ? [] : isDashboardFromList ? sortedFolders[0] : data;

	return [response, error, loading];
}
