import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import * as CSS from 'csstype';
import { useReportEvent } from 'src/services/analytics';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';
import { ButtonColorSchemaType, ButtonSizeType, ButtonVariantType } from './types';

export type ButtonProps = {
	testId?: string;
	colorScheme?: ButtonColorSchemaType;
	size?: ButtonSizeType;
	variant?: ButtonVariantType;
	eventName?: string;
	eventMetadata?: object;
	ml?: string | number;
	isIconOnly?: boolean;
	borderRadius?: string;
} & Omit<ChakraButtonProps, 'colorScheme' | 'variant' | 'size'>;

function Button({
	variant = 'ghost',
	colorScheme = 'blue',
	children,
	isDisabled,
	isLoading,
	onClick,
	loadingText,
	size = 'medium',
	rightIcon,
	leftIcon,
	spinnerPlacement,
	testId,
	isActive,
	width,
	eventName,
	eventMetadata,
	ml,
	isIconOnly = false,
	mixBlendMode,
	borderRadius = '8px',
	...props
}: ButtonProps) {
	const { wrapWithReport } = useReportEvent();

	return (
		<ChakraButton
			data-testid={testId}
			isLoading={isLoading}
			loadingText={loadingText}
			variant={variant}
			onClick={wrapWithReport(onClick, eventName, eventMetadata)}
			size={size}
			rightIcon={rightIcon}
			leftIcon={leftIcon}
			colorScheme={colorScheme}
			isDisabled={isDisabled}
			spinnerPlacement={spinnerPlacement}
			isActive={isActive}
			width={width}
			ml={ml}
			{...props}
			py={isIconOnly ? iconOnlyPaddingBySize[size] : undefined}
			px={isIconOnly ? iconOnlyPaddingBySize[size] : undefined}
			maxWidth={isIconOnly ? iconOnlyWidthBySize[size] : undefined}
			mixBlendMode={mixBlendMode}
			borderRadius={borderRadius}
		>
			<Typography
				as="span"
				marginBottom={isIconOnly ? '0' : marginBottomBySize[size]}
				variant={typographyVariantBySize[size]}
				style={{
					cursor: 'pointer',
				}}
			>
				{children}
			</Typography>
		</ChakraButton>
	);
}

export default Button;

const typographyVariantBySize: { [key in ButtonSizeType]: TypographyVariant } = {
	micro: 'Paragraph12M',
	xxxs: 'Paragraph12M',
	xxs: 'Paragraph12M',
	xs: 'Paragraph12M',
	inline: 'DesktopH8Medium',
	small: 'DesktopH8Medium',
	medium: 'DesktopH7Medium',
	large: 'DesktopH7Medium',
};

const iconOnlyPaddingBySize: { [key in ButtonSizeType]: CSS.Property.Padding } = {
	micro: '0',
	xxxs: '2px',
	xxs: '4px',
	xs: '6px',
	inline: '8px',
	small: '10px',
	medium: '13px',
	large: '15px',
};

const iconOnlyWidthBySize: { [key in ButtonSizeType]: CSS.Property.Width } = {
	micro: '16px',
	xxxs: '20px',
	xxs: '24px',
	xs: '28px',
	inline: '32px',
	small: '36px',
	medium: '42px',
	large: '48px',
};

const marginBottomBySize: { [key in ButtonSizeType]: CSS.Property.Margin } = {
	micro: '0',
	xxxs: '0',
	xxs: '0',
	xs: '0',
	inline: '2px',
	small: '2px',
	medium: '3px',
	large: '2px',
};
