import React, { useCallback, useRef, useState } from 'react';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import Box from '@components/Box';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import Flex from '@components/Flex';
import classNames from 'classnames';
import { SpaceMoreButton } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem/SpaceMoreButton';
import { Collection } from '@layout/Menu/NavigationDrawer/types';
import useNavigation, { NavigationOptions } from '@services/useNavigation';
import { useReportEvent } from '@services/analytics';
import { useLocation } from 'react-router-dom';
import DeleteCollectionModal from '@layout/Menu/NavigationDrawer/DeleteCollectionModal';
import { useModal } from '@hooks/ui/useModal';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { MoveToFolderModal } from '../../../DashboardFolder';

function SpaceName({
	categoryName,
	testId,
	cursor,
}: {
	categoryName: string;
	testId: string;
	cursor: 'grap' | 'pointer';
}) {
	const nameRef = useRef(null);
	const isOverflowingCategoryName = useOnOverflow(nameRef, [categoryName], undefined, false);
	return (
		<Tooltip
			size="md"
			variant="fluid"
			placement={'right'}
			marginLeft={'38px'}
			label={isOverflowingCategoryName && categoryName}
		>
			<Typography
				noOfLines={1}
				cursor={cursor}
				variant="DesktopH8Regular"
				color={'natural.1000'}
				wordBreak={'break-all'}
			>
				<Box ref={nameRef} data-testid={`${testId}-text`}>
					{categoryName}
				</Box>
			</Typography>
		</Tooltip>
	);
}

export const SpaceListItem = ({
	currentDrawerView,
	collection,
	index,
	setIsPanelActingLikeOnHover,
	testId,
	padding = '8px 6px 8px 16px',
	isTransparent = false,
	isDragging,
}: {
	currentDrawerView: string;
	collection: Collection;
	index: number;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	testId: string;
	padding?: string;
	isTransparent?: boolean;
	isDragging?: boolean;
}) => {
	const { reportEvent } = useReportEvent();
	const location = useLocation();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const {
		isOpen: isCreateEditModalOpen,
		onOpen: onCreateDashboardModalOpen,
		onClose: onCreateEditModalClose,
	} = useModal();
	const { isOpen: isMoveToModalOpen, onOpen: onMoveToModalOpen, onClose: onMoveToModalClose } = useModal();
	const [actionItem, setActionItem] = useState<Collection>();
	const { navigate } = useNavigation();

	const navigateToCollection = useCallback(
		(
			collectionType: string,
			collectionId: string,
			collectionName: string,
			navigate: (navOptions: NavigationOptions) => void,
			isNewTab?: boolean
		) => {
			reportEvent({
				event: 'collection-tiles-navigate-to-collection',
				metaData: {
					// String names for backwards-compatible with previous analytics
					'collection-name': collectionName,
					'collection-type': collectionType,
					collectionId,
					isNewTab,
				},
			});
			const URL = `${collectionType.toLowerCase()}/${collectionId}`;

			navigate({ path: URL, isNewTab });
		},
		[reportEvent]
	);

	// TAF: Changed to URL params
	const isItemSelected = useCallback(
		(id: number, collectionType: string) =>
			location.pathname === `/${collectionType.toLowerCase()}/${id}` ||
			(location.pathname === '/dashboard' && index === 0),
		[location.pathname, index]
	);

	const handleMenuItemClick = useCallback(
		(collection: Collection) => {
			navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, false);
		},
		[navigate, navigateToCollection]
	);

	return (
		<>
			<Flex
				className={classNames(classes.menuIndicator, {
					[classes.selected]: isItemSelected(collection.id, collection.collection_type),
					[classes.isTransparent]: isTransparent,
					[classes.isDraggingDashboard]: isDragging,
				})}
				padding={padding}
				onClick={() => handleMenuItemClick(collection)}
				data-testid={testId}
				data-intercom-area={`${currentDrawerView}-sidebar`}
				data-intercom-type={'button'}
				data-intercom-sequence={index + 1}
				justifyContent={'space-between'}
				cursor={'pointer'}
			>
				<SpaceName cursor={'pointer'} categoryName={collection.name} testId={testId} />
				<SpaceMoreButton
					collection={collection}
					setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
					onDeleteModalOpen={onDeleteModalOpen}
					onCreateEditModalOpen={onCreateDashboardModalOpen}
					onUpsertFolderModalOpen={onMoveToModalOpen}
					setActionItem={setActionItem}
					navigateToCollection={navigateToCollection}
				/>
			</Flex>

			{actionItem && (
				<DeleteCollectionModal
					isOpen={isDeleteModalOpen}
					onClose={onDeleteModalClose}
					collection={actionItem}
					isCollectionInView={false}
				/>
			)}
			<CollectionUpsertModal
				isOpen={isCreateEditModalOpen}
				onClose={() => {
					onCreateEditModalClose();
					setActionItem(undefined);
				}}
				collection={actionItem}
				collectionType={actionItem?.collection_type || currentDrawerView}
			/>
			<MoveToFolderModal collection={actionItem} isOpen={isMoveToModalOpen} onClose={onMoveToModalClose} />
		</>
	);
};
