import { Droppable, Draggable, DroppableProvided } from '@hello-pangea/dnd';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Popover from '@components/Popover';
import { FolderData, UpsertFolderModal } from './UpsertFolderModal';
import Typography from '../../Typography';
import Button from '@components/Button';
import {
	DashboardNew18,
	DeleteTrash16,
	EditFull16,
	More16,
	NumberStepperDown6,
	NumberStepperRight6,
} from '../../Icons';
import { AccessController } from '../../AccessController';
import { Permissions } from 'src/types/environment';
import Divider from '../../Divider';
import ListItem from '../../ListItem';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { useMemo, useRef, useState } from 'react';
import Tooltip from '../../Tooltip';
import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import shadows from 'src/style/shadows';
import colors from 'src/style/colors';
import { useModal } from 'src/common/hooks/ui/useModal';
import { DeleteFolderModal } from '.';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { useReportEvent } from 'src/services/analytics';
import { SpaceListItem } from '../SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem';
import { TestIDs } from 'src/common/types/test-ids';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import classes from './DashboardFolder.module.scss';
import useNavigation from 'src/services/useNavigation';
import { ListAllFoldersSubscription } from 'src/generated/graphql';

export const DashboardFolder = ({
	folder,
	dashboards,
	setIsPanelActingLikeOnHover,
	index,
	isDragging,
}: {
	folder: FolderData;
	dashboards: ListAllFoldersSubscription['folders'][number]['dashboards'] | [];
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	index: number;
	isDragging?: boolean;
}) => {
	const {
		folderWrapper,
		isActive,
		dashboardsListWrapper,
		isExpanded,
		isDraggingFolder,
		arrowIconWrapper,
		actionsWrapper,
	} = classes;

	const titleRef = useRef(null);
	const isOverflowingTitle = useOnOverflow(titleRef, [folder.folderName], undefined, false);
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const {
		isOpen: isCreateFolderModalOpen,
		onOpen: onCreateFolderModalOpen,
		onClose: onCreateFolderModalClose,
	} = useModal();
	const {
		isOpen: isCreateEditCollectionModalOpen,
		onOpen: onCreateEditCollectionModalOpen,
		onClose: onCreateEditCollectionModalClose,
	} = useModal();

	const useIsFolderActive = () => {
		const location = useLocation();
		const isFolderActive = useMemo(() => {
			return (
				(folder.id && location.pathname.includes(folder.id)) ||
				folder?.dashboards?.some((dashboard) => location.pathname.includes(dashboard.id)) ||
				(location.pathname === '/dashboard' && index === 0)
			);
		}, [location.pathname]);
		return isFolderActive;
	};

	const isFolderActive = useIsFolderActive();

	const [isFolderOpened, setIsFolderOpened] = useState(isFolderActive);

	const dashboardsList = useMemo(
		() => (
			<Droppable droppableId={`droppable-dashboard-${folder.id}`} type={`dashboards-subItem-${folder.id}`}>
				{(provided: DroppableProvided) => (
					<Box ref={provided.innerRef}>
						{dashboards.map((dashboard, index) => (
							<Draggable key={dashboard.id} draggableId={`${dashboard.id}`} index={index}>
								{(provided, snapshot) => (
									<Box {...provided.dragHandleProps}>
										<Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
											<SpaceListItem
												isDragging={snapshot.isDragging}
												padding="8px 6px 8px 44px"
												key={dashboard.id}
												currentDrawerView={'dashboard-folders'}
												collection={{ ...dashboard, folder_id: folder.id, collection_type: 'dashboard' }}
												index={index}
												setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
												testId={TestIDs.SIDEBAR_ITEM('dashboard')}
											/>
										</Box>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		),
		[setIsPanelActingLikeOnHover, folder, dashboards]
	);

	if (!folder.id) return;

	const onCreateDashboard = () => navigate({ path: `dashboard/folder/${folder.id}` });

	const handleDelete = () => {
		reportEvent({ event: 'sidebar-delete-item', metaData: { itemType: 'folder', name: folder.folderName } });
		onClose();
		onDeleteModalOpen();
	};

	const handleEdit = () => {
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		onClose();
		onCreateFolderModalOpen();
	};

	const dashboardListEnabled = folder?.dashboards && folder?.dashboards?.length > 0;

	const handleCreateDashboard = () => {
		reportEvent({ event: 'sidebar-create-new-dashboard-in-folder', metaData: { name: folder.folderName } });
		onClose();
		onCreateEditCollectionModalOpen();
	};

	const arrowIcon = (
		<Box className={arrowIconWrapper} width={'6px'} color={colors.gray[1000]}>
			{isFolderOpened ? <NumberStepperDown6 /> : <NumberStepperRight6 />}
		</Box>
	);

	const createDashboardPlaceholder = (
		<Flex
			_hover={{
				backgroundColor: 'collectionHoverColor',
			}}
			backgroundColor={isFolderActive ? 'collectionActiveColor' : 'transparent'}
			onClick={onCreateDashboard}
			padding={'8px 8px 8px 44px'}
			borderRadius={'6px'}
		>
			<Typography color={'natural.500'} variant={'DesktopH8Regular'}>
				Create a dashboard
			</Typography>
		</Flex>
	);

	const listWrapper = (
		<Box
			className={classNames(dashboardsListWrapper, {
				[isExpanded]: isFolderOpened,
			})}
		>
			{dashboardListEnabled ? dashboardsList : createDashboardPlaceholder}
		</Box>
	);

	const PopoverMenuItems = ({
		onEdit,
		onDelete,
		onCreateDashboard,
	}: {
		onEdit: VoidFunction;
		onDelete: VoidFunction;
		onCreateDashboard: VoidFunction;
	}) => (
		<>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="224px" borderRadius="4px">
					<AccessController permission={Permissions.createCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Create a dashboard..."
							prefixIcon={<DashboardNew18 />}
							onClick={onCreateDashboard}
						/>
						<Box px={'12px'}>
							<Divider direction="horizontal" my="8px" />
						</Box>
					</AccessController>
					<AccessController permission={Permissions.updateCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Edit"
							prefixIcon={<EditFull16 />}
							onClick={onEdit}
						/>
					</AccessController>
					<Box px={'12px'}>
						<Divider direction="horizontal" my="8px" />
					</Box>
					<AccessController permission={Permissions.deleteCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Delete"
							prefixIcon={<DeleteTrash16 />}
							onClick={onDelete}
						/>
					</AccessController>
				</Flex>
			</PopoverContent>
		</>
	);

	return (
		<Box>
			<Flex
				className={classNames(folderWrapper, {
					[isActive]: !isFolderOpened && isFolderActive,
					[isDraggingFolder]: isDragging,
				})}
				onClick={() => setIsFolderOpened(!isFolderOpened)}
				justifyContent={'space-between'}
				alignItems={'center'}
				width={'100%'}
				borderRadius={'6px'}
				transition={'0.2s'}
				_hover={{
					backgroundColor: 'collectionHoverColor',
				}}
				padding={'8px'}
			>
				<Flex alignItems={'center'} gap={'8px'}>
					<Flex alignItems={'center'} gap={'5px'}>
						{arrowIcon}
						<Box fontSize={'20px'} lineHeight={'20px'}>
							{folder.emoji}
						</Box>
					</Flex>
					<Box>
						<Tooltip size="md" variant="fluid" label={isOverflowingTitle && folder.folderName}>
							<Typography wordBreak={'break-all'} noOfLines={1} variant={'DesktopH8Regular'} color={'gray.1000'}>
								<Box ref={titleRef}>{folder.folderName}</Box>
							</Typography>
						</Tooltip>
					</Box>
				</Flex>
				<Flex className={actionsWrapper}>
					<Popover
						placement="bottom-start"
						variant="solid"
						isOpen={isOpen}
						onClose={onClose}
						triggerElement={
							<Flex position="relative">
								<Button
									borderRadius={'4px'}
									size="xxxs"
									variant="outline"
									colorScheme="gray"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										if (isOpen) onClose();
										else onOpen();
									}}
									isActive={isOpen}
									isIconOnly={true}
									color={'natural.800'}
									_hover={{ bgColor: 'rgba(245, 245, 245, 0.50)' }}
									blendMode={'multiply'}
								>
									<More16 />
								</Button>
							</Flex>
						}
						popoverContentProps={{
							boxShadow: shadows['panelShadow'],
							borderRadius: '8px',
							border: `1px solid ${colors.gray[300]}`,
						}}
					>
						<PopoverMenuItems onCreateDashboard={handleCreateDashboard} onEdit={handleEdit} onDelete={handleDelete} />
					</Popover>
					<CollectionUpsertModal
						folderId={folder.id}
						isOpen={isCreateEditCollectionModalOpen}
						onClose={onCreateEditCollectionModalClose}
						collectionType={'dashboard'}
					/>
				</Flex>
				<UpsertFolderModal folder={folder} isOpen={isCreateFolderModalOpen} onClose={onCreateFolderModalClose} />
				<DeleteFolderModal folder={folder} isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} />
			</Flex>
			{listWrapper}
		</Box>
	);
};
