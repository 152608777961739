import { Box, ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { CloseLarge16 } from '@components/Icons';
import { FolderSelect } from '@components/FolderSelect';
import Modal from '@components/Modal';
import colors from 'src/style/colors';
import { useState } from 'react';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { GetCollectionsSubscription, ListAllFoldersSubscription } from 'src/generated/graphql';
import { DEFAULT_EMOJIPICKER_VALUE } from '../../EmojiPicker/EmojiPicker';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { UpdateCollection } from 'src/queries/collections';
import useToast from 'src/common/hooks/ui/useToast';
import Divider from '../../Divider';
import useNavigation from 'src/services/useNavigation';
import { useReportEvent } from 'src/services/analytics';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	collection?: GetCollectionsSubscription['workspaces'][0];
};

export type FolderData = {
	emoji: string;
	folderName: string;
	id?: string;
	dashboards?: ListAllFoldersSubscription['folders'][number]['dashboards'];
};

export const initialFolderData = {
	emoji: DEFAULT_EMOJIPICKER_VALUE,
	folderName: '',
};

export const MoveToFolderModal = ({ isOpen, onClose, collection }: ModalProps) => {
	const closeModal = () => {
		setFolderData(initialFolderData);
		setFolderId('');
		onClose();
	};

	const [folderData, setFolderData] = useState<FolderData>(initialFolderData);
	const [folderId, setFolderId] = useState('');
	const { navigate } = useNavigation();
	const toast = useToast();
	const { reportEvent } = useReportEvent();

	const { createDashboardFolder, isCreateFolderLoading } = useDashboardFolders();
	const [collectionMutate, { loading: isCollectionLoading }] = useMutation(UpdateCollection);

	const isLoading = isCreateFolderLoading || isCollectionLoading;
	const isSubmitDisabled = !folderId ? !folderData.emoji || !folderData.folderName : !folderId;

	const onViewNow = () => {
		const URL = `dashboard/${collection?.id}`;
		navigate({ path: URL });
	};

	const onSubmit = async () => {
		if (folderId) {
			await onSubmitRequests();
		} else {
			reportEvent({
				event: 'sidebar-move-dashboard-create-new-folder-and-move',
				metaData: { field: 'folder', fodlerName: folderData.folderName },
			});
			await createDashboardFolder(folderData.folderName, folderData.emoji, false).then((res) =>
				onSubmitRequests(res?.insert_folders_one?.id)
			);
		}
		closeModal();
	};

	const onSubmitRequests = async (newFolderId?: string) => {
		const folder_id = newFolderId || folderId;
		try {
			await collectionMutate({
				variables: {
					...collection,
					folder_id,
				},
			})
				.then(() => {
					toast({
						variant: 'ok',
						message: (
							<Flex>
								<Typography textAlign={'center'} variant="Paragraph14R" color="gray.1000">
									Successfully moved to{' '}
								</Typography>
								&nbsp;
								<Typography textAlign={'center'} variant="Paragraph14SB" color="gray.1000">
									{folderData.folderName}
								</Typography>
								<Box>
									<Divider margin={'0 12px'} width="1px" direction={'vertical'} />
								</Box>
								<Typography
									onClick={onViewNow}
									cursor={'pointer'}
									textDecoration={'underline'}
									textAlign={'center'}
									variant="Paragraph14M"
									color="gray.1000"
								>
									View now
								</Typography>
							</Flex>
						),
						duration: 5000,
					});
				})
				.finally(closeModal);
		} catch (error) {
			if (error instanceof Error) toast({ variant: 'error', message: error.message });
		}
	};

	return (
		<Modal maxWidth="460px" isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} isCentered>
			<ModalHeader boxShadow={'borderBottom'} p="16px">
				<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
					<Button isIconOnly variant="outline" onClick={closeModal} size="inline" colorScheme="black">
						<CloseLarge16 color={colors.gray[900]} />
					</Button>
					<Box margin={'0 auto'}>
						<Typography color={'gray.1000'} marginLeft={'-32px'} variant="DesktopH7Medium">
							Move to...
						</Typography>
					</Box>
				</Flex>
			</ModalHeader>
			<Flex width={'100%'} gap={'24px'} flexDirection={'column'} boxShadow={'borderBottom'} padding={'24px'}>
				<FolderSelect
					isInitSelect
					folderId={folderId}
					itemType={'folder'}
					setFolderId={(folder_id, folderName) => {
						setFolderId(folder_id);
						setFolderData({ folderName: folderName || '', emoji: '' });
					}}
					setFolderData={(folderData) => {
						setFolderId('');
						setFolderData(folderData);
					}}
				/>
			</Flex>
			<Flex py="16px" px="24px" justifyContent={'space-between'}>
				<Button color={'gray.1000'} size={'medium'} colorScheme="gray" variant="outline" onClick={closeModal}>
					Cancel
				</Button>
				<Button
					isLoading={isLoading}
					isDisabled={isSubmitDisabled}
					size={'medium'}
					variant="solid"
					colorScheme={isSubmitDisabled ? 'gray' : 'blue'}
					onClick={onSubmit}
				>
					{'Move'}
				</Button>
			</Flex>
		</Modal>
	);
};
