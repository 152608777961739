import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import Box from '@components/Box';
import { ListAllFoldersSubscription, useListAllFoldersSubscription } from 'src/generated/graphql';
import useUser from 'src/common/hooks/stores/useUser';
import { DashboardFolder } from './index';
import _ from 'lodash';
import { FolderData } from './UpsertFolderModal';
import { SkeletonFolder } from './Skeletons/SkeletonFolder';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { useReportEvent } from 'src/services/analytics';
import './DraggableFolders.scss';

export const DraggableFolders = ({
	setIsPanelActingLikeOnHover,
}: {
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
}) => {
	const [{ id: my_id }] = useUser();
	const { data: foldersList, loading: isFoldersLoading } = useListAllFoldersSubscription({
		variables: { my_id: my_id },
	});
	const { reorderDashboardFolder, reorderFolderDashboard } = useDashboardFolders();
	const [folderNodes, setFolderNodes] = useState<FolderData[]>([]);
	const [dashboardNodes, setDashboardNodes] = useState<ListAllFoldersSubscription['folders'][number]['dashboards'][]>(
		[]
	);

	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const sortedFolders = useMemo(
		() =>
			_.sortBy(foldersList?.folders, 'order').map((folder) => ({
				id: folder.id,
				folderName: folder.name,
				emoji: folder.emoji,
				dashboards: folder.dashboards,
				order: folder.order,
			})),
		[foldersList?.folders]
	);

	const sortedDashboards = useMemo(
		() => sortedFolders.map((folder) => folder.dashboards).map((el) => _.sortBy(el, 'order')),
		[sortedFolders]
	);

	useEffect(() => {
		setFolderNodes(sortedFolders);
		setDashboardNodes(sortedDashboards);
	}, [sortedFolders, sortedDashboards]);

	const getOrder = (
		items: FolderData[] | ListAllFoldersSubscription['folders'][number]['dashboards'],
		itemId: string
	) => {
		const index = items.findIndex((item) => item.id === itemId);
		if (index === -1) return;
		if (index === 0) {
			const order = items[1].order;
			if (!order) return;
			return order / 2;
		}
		if (index === items.length - 1) return Date.now() * 1000;
		const prevOrder = items[index - 1].order;
		const nextOrder = items[index + 1].order;
		if (!prevOrder || !nextOrder) return;
		return (prevOrder + nextOrder) / 2;
	};

	const reorderFolders = useCallback(
		async (items: FolderData[], folderId: string) => {
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'folder' } });
			const newOrder = getOrder(items, folderId);
			if (newOrder) await reorderDashboardFolder(folderId, newOrder);
		},
		[reorderDashboardFolder, reportEvent]
	);

	const reorderDashboards = useCallback(
		async (
			items: ListAllFoldersSubscription['folders'][number]['dashboards'],
			dashboarId: string,
			folderId?: string
		) => {
			if (!items || !dashboarId || !folderId) return;
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'dashboard' } });
			const newOrder = getOrder(items, dashboarId);
			if (newOrder) await reorderFolderDashboard(dashboarId, folderId, newOrder);
		},
		[reorderFolderDashboard, reportEvent]
	);

	const onDragEnd = useCallback(
		async (result: DropResult) => {
			if (!result.destination) return;
			if (result.type === 'folders') {
				const items = [...folderNodes];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				setFolderNodes(items);
				setDashboardNodes(items?.map((folder) => folder?.dashboards || []).map((el) => _.sortBy(el, 'order')));
				await reorderFolders(items, result.draggableId);
			} else if (result.type.includes('dashboards-subItem')) {
				const foundDashboardNode = dashboardNodes?.find((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				const items = foundDashboardNode ? [...foundDashboardNode] : [];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				const foundDashboardNodeIndex = dashboardNodes?.findIndex((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				if (foundDashboardNodeIndex !== undefined && foundDashboardNodeIndex !== -1) {
					const updatedDashboardNodes = [...dashboardNodes];
					updatedDashboardNodes[foundDashboardNodeIndex] = items;
					setDashboardNodes(updatedDashboardNodes);
					const folder = folderNodes.find((folder) =>
						folder?.dashboards?.some((dashboard) => dashboard.id === result.draggableId)
					);
					await reorderDashboards(items, result.draggableId, folder?.id);
				}
			}
		},
		[folderNodes, dashboardNodes, reorderFolders, reorderDashboards]
	);

	const doppableContent = useMemo(
		() => (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-folders" type="folders">
					{(provided) => (
						<Box
							data-intercom-area="dashboards"
							data-intercom-type="panel"
							data-intercom-target="dashboards-panel"
							ref={provided.innerRef}
						>
							{folderNodes.map((folder, index) => (
								<Draggable key={folder.id} draggableId={`${folder.id}`} index={index}>
									{(provided, snapshot) => (
										<Box {...provided.dragHandleProps}>
											<Box ref={provided.innerRef} {...provided.draggableProps}>
												<Box
													{...(index === 0 && {
														'data-intercom-area': 'dashboards',
														'data-intercom-type': 'panel',
														'data-intercom-target': 'dashboards-first-item',
													})}
												>
													<Box>
														<DashboardFolder
															isDragging={snapshot.isDragging}
															index={index}
															dashboards={dashboardNodes[index]}
															setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
															folder={folder}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>
		),
		[onDragEnd, folderNodes, setIsPanelActingLikeOnHover, dashboardNodes]
	);

	return isFoldersLoading ? <SkeletonFolder /> : doppableContent;
};
